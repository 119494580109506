<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
    export default {
        name: 'App',
        data() {
            return {
                getWidth: window.screen.width
            }
        },
        created() {
            window.onresize = () => {
                if (document.documentElement.clientWidth <= 768) {
                    location.href = 'https://www.szart.cn/h5'
                    // console.log("<768")
                }
            }
            var ua = navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                location.href = 'https://www.szart.cn/h5'
            }
        },
        methods: {

        },
        watch: {

        }
    }
</script>
<style lang="scss">
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
</style>
